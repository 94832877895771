import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'

import {Site} from '../response'
import {StringParams} from '../types'

export const getSites =
  (api: AxiosInstance) =>
  (params: StringParams = {}, axiosConfig?: AxiosRequestConfig): Promise<AxiosResponse<Site[]>> => {
    const config = {params, ...axiosConfig}
    return api.get('/sites', config)
  }

export const getSite =
  (api: AxiosInstance) =>
  (
    id: any,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<Site>> => {
    const config = {params, ...axiosConfig}
    return api.get(`/sites/${id}`, config)
  }
