import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'

import {Project} from '../response'
import {StringParams} from '../types'

export const getProject =
  (api: AxiosInstance) =>
  (
    projectId: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<Project>> => {
    const config = {params, ...axiosConfig}
    return api.get(`/projects/${projectId}`, config)
  }

export const getProjects =
  (api: AxiosInstance) =>
  (
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<Project[]>> => {
    const config = {params, ...axiosConfig}
    return api.get('/projects', config)
  }
