import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {Haulier} from '../response'
import {SchemaIDs} from '../schemas'
import {StringParams} from '../types'

const {HaulierSchemaID, HauliersSchemaID} = SchemaIDs

export const getHaulierById =
  (api: AxiosInstance) =>
  async (
    haulierId: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<Haulier> => {
    const config = {params, schemaId: HaulierSchemaID, ...axiosConfig}
    const response = await api.get<Haulier>(`/hauliers/${haulierId}`, config)
    return response.data
  }

export const getHaulierByNumber =
  (api: AxiosInstance) =>
  async (
    haulierNumber: string | undefined,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<Haulier[]> => {
    const config = {params, schemaId: HauliersSchemaID, ...axiosConfig}
    const response = await api.get<Haulier[]>(`/hauliers?haulierNumber=${haulierNumber}`, config)
    return response.data
  }
