import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {Payer} from '../response'
import {SchemaIDs} from '../schemas'
import {StringParams} from '../types'

const {CustomerSchemaID, CustomersSchemaID} = SchemaIDs

// API defined endpoints
export const getPayerById =
  (api: AxiosInstance) =>
  async (
    payerId: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<Payer> => {
    const config = {params, schemaId: CustomerSchemaID, ...axiosConfig}
    const response = await api.get<Payer>(`/payers/${payerId}`, config)
    return response.data
  }

export const getPayerByNumber =
  (api: AxiosInstance) =>
  async (
    payerNumber: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<Payer[]> => {
    const config = {params, schemaId: CustomersSchemaID, ...axiosConfig}
    const response = await api.get<Payer[]>(`/payers?payerNumber=${payerNumber}`, config)
    return response.data
  }

export const getPayerWithoutNumber =
  (api: AxiosInstance) =>
  async (params: StringParams = {}, axiosConfig?: AxiosRequestConfig): Promise<Payer[]> => {
    const config = {params, schemaId: CustomersSchemaID, ...axiosConfig}
    const response = await api.get<Payer[]>('/payers', config)
    return response.data
  }
