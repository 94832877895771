import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {Plant} from '../response'
import {StringParams} from '../types'

export const getPlantById =
  (api: AxiosInstance) =>
  async (
    plantId: string,
    countryId: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<Plant> => {
    const config = {params, ...axiosConfig}
    const response = await api.get<Plant>(`/plants/${plantId}?countryId=${countryId}`, config)
    return response.data
  }
